<template lang='pug'>
  div
    .text-xl.font-bold Список адресов
    .text-sm.mb-4 Вы можете просмотреть и настроить адреса

    template(v-if='addresses')
      p-table(
        :data='addresses'
        :search='true'
        :headers='{ "country": "Страна", "addressString": "Адрес", "ownerString": "Владелец", "ownerNumber": "Номер телефона" }'
        :actions='{ }'
      )
    template(v-else)
      .text-sm.opacity-20.mt-5 Идёт загрузка данных...
</template>

<script>
import { getAddresses } from '../assets/api.js';

export default {
  mounted() {
    this.loadData();
  }, 
  data() {
    return {
      addresses: null
    }
  },
  methods: {
    /**
     * Загрузить данные о складах из БД
     */
    async loadData() {
      const result = await getAddresses();
      if (!result.success) {
        console.error('Ошибка получения списка полок');
        return;
      }

      result.data.forEach(value => {
        value.addressString = `${value.country}, ${value.address1}, ${value.index}`;
        value.ownerString = `${value.personal.first_name} ${value.personal.last_name}`;
        value.ownerNumber = value.personal.number;
      });

      this.addresses = result.data;
    }
  }
}
</script>

<style>

</style>